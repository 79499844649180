.actions {
  padding-right: 80px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 12px;
}

.mobileActions {
  padding-right: 20px;
  padding-left: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.editorActions {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 12px;
}

.newsFeed {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 84px);
  outline: none;
}

.newsBody {
  max-width: 800px;
  align-items: center;
  padding: 0px 80px 36px 80px;
  outline: none;
}

.mobileNewsBody {
  align-items: center;
  padding: 0px 20px 36px 20px;
  outline: none;
}

.title {
  display: grid;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--dig-color__background__base);
  padding-bottom: 8px;
}
