.teamBodyContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.teamNameButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamInfosContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 24px;
}

.teamInfoContainer {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-start;
}

.teamInfoIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  background: var(--dig-color__opacity__surface);
  border-radius: 40px;
  width: 40px;
  height: 40px;
}

.supportingTeamContainer {
  border-radius: 8px;
}

.teamInfoIcon {
  color: var(--dig-color__text__subtle);
}

.supportingTeamInfoIcon {
  width: 28px;
  height: 28px;
  color: var(--dig-color__border__base);
}

.teamInfoTextContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.teamMembersContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.teamInfoTextContainer a {
  color: inherit;
  text-decoration: none;
}

.teamInfoTextContainer a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 860px) {
  .teamBodyContainer {
    margin-top: 0px;
  }

  .teamMembersContainer {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .teamInfosContainer {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}
