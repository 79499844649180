:global(*) {
  /* stylelint-disable-next-line dig/var-recommended-colors */
  --dig-color__primary-cta__base: #0061fe !important;
  /* stylelint-disable-next-line dig/var-recommended-colors */
  --dig-color__primary-cta__base--state-1: #004dc7 !important;
  /* stylelint-disable-next-line dig/var-recommended-colors */
  --dig-color__primary-cta__base--state-2: #003a97 !important;
}

/* Border radius */
:global(.dig-Select-trigger),
:global(.dig-Typeahead-container),
:global(.dig-TextArea),
:global(.dig-TextInputContainer) {
  border-radius: 8px !important;
}

/* Bold overrides */
:global(.dig-Tabs-tab) {
  font-weight: 500;
}

:global(.dig-Breadcrumb) {
  margin-top: 0;
}

:global(.dig-List-item--isSelected) {
  background-color: var(--dig-color__opacity__surface--state-1) !important;
}

:global(.dpc-GlobalHeader) {
  background-color: var(--dig-color__background__base);
  position: fixed;
  width: 100%;
  z-index: 11;
  left: 0;
  padding-left: 12px;
}

h2[id] {
  scroll-margin-top: 108px;
}

/* TODO, this should be removed */
:global(.dig-Modal-body--open),
:global(.dig-Modal-html--open) {
  overflow: initial !important;
}
