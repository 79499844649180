.body {
  display: contents;
}

/* Basic header cell styling */
.headerCell {
  position: relative;
  padding: 6px 8px;
  font-weight: bold;
  user-select: none; /* prevents text highlighting during resize drag */
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--dig-color__background__subtle);
}

/* Body cells */
.cell {
  padding: 8px;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.cell > * {
  flex: 1;
}

.row.noPadding .cell {
  padding: 0;
}

.row:not(:last-child) .cell,
.header .headerCell {
  border-bottom: 1px solid var(--dig-color__opacity__surface--state-2);
}

.selectable .cell {
  cursor: pointer;
}

.selectable:hover .cell {
  background-color: var(--dig-color__opacity__surface);
}

.selectable:active .cell {
  background-color: var(--dig-color__opacity__surface--state-2);
}

.selected .cell {
  background-color: var(--dig-color__opacity__surface--state-2);
}

/* Resizer handle styling */
.resizeHandle {
  position: absolute;
  top: 0;
  right: -9px;
  z-index: 1;
  visibility: hidden;
  cursor: col-resize;
}

/* Show all handles on header hover or when dragging */
.header:hover .resizeHandle,
.isDragging {
  visibility: visible;
}

/* Hide all handles except the dragging one when dragging */
.header:has(.isDragging) .resizeHandle:not(.isDragging) {
  visibility: hidden;
}

/* Add a border between columns if desired */
.verticalLines .cell:not(:last-child) {
  border-right: 1px solid var(--dig-color__opacity__surface--state-2);
}

.tableWrapper {
  position: relative;
}

.shadowOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--duration__125) var(--easing__transition);
  z-index: 1;
}

.leftShadow {
  left: 0;
  background: linear-gradient(to right, var(--dig-color__background__subtle) 20%, transparent);
  border-top-left-radius: var(--dig-radius__medium);
  border-bottom-left-radius: var(--dig-radius__medium);
}

.rightShadow {
  right: 0;
  background: linear-gradient(to left, var(--dig-color__background__subtle) 20%, transparent);
  border-top-right-radius: var(--dig-radius__medium);
  border-bottom-right-radius: var(--dig-radius__medium);
}

.shadowOverlay.visible {
  opacity: 0.9;
}

.dragHandle {
  visibility: hidden;
}

.row:hover .dragHandle,
.dragHandle.isDragging {
  visibility: visible;
}

.row {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
}
