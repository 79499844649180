.goalTable :global(.dig-Table-body .dig-Table-cell),
.goalTable :global(.dig-Table-header .dig-Table-cell) {
  border: none !important;
}

.goalTable :global(.dig-Table-header .dig-Table-cell) {
  border-bottom: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.goalTable :global(.dig-Table-header .dig-Table-cell) {
  border-top: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.goalTable :global(.dig-Table-body .dig-Table-cell:first-of-type),
.goalTable :global(.dig-Table-header .dig-Table-cell:first-of-type) {
  border-left: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.goalTable :global(.dig-Table-body .dig-Table-cell:last-of-type),
.goalTable :global(.dig-Table-header .dig-Table-cell:last-of-type) {
  border-right: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.goalTable :global(.dig-Table-body .dig-Table-cell) {
  padding: 0 !important;
}

.goalTable :global(.dig-Table-body .dig-Table-row:last-of-type .dig-Table-cell) {
  border-bottom: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.profileTable :global(.dig-Table-body .dig-Table-cell),
.profileTable :global(.dig-Table-header .dig-Table-cell) {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
}

.profileTable :global(.dig-Table-body .dig-Table-cell:last-of-type) {
  padding-right: 8px !important;
}
