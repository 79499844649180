.searchContainer {
  width: 100%;
  max-width: 820px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--dig-color__background__base);
}

.typeaheadContainer {
  width: 100%;
}

.typeaheadResultsContainer {
  border: 1px solid var(--dig-color__border__subtle);
  background: var(--dig-color__background__base);
  border-radius: 8px;
  padding-bottom: 0px;
}

.typeaheadRow {
  border-radius: 8px;
}
