:global(.dig-Menu-row[hidden]) {
  display: none;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-right: 18px;
}
