.labels {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing__unit--4);
  justify-content: center;
}

.labels span:hover {
  cursor: pointer;
}

.selected {
  position: relative;
}

/* This is a hack to mitigate the fact that setting letter-spacing on LabelText
 * causes the border-bottom and/or text-decoration: underlined to overflow.
 */
.selected:after {
  border-bottom: solid 1px var(--dig-color__text__base);
  content: '';
  display: block;
  position: absolute;
  right: 3.84px;
  left: 0;
}

@media only screen and (max-width: 860px) {
  .labels {
    row-gap: var(--spacing__unit--2);
  }
}
