@font-face {
  font-family: 'Gill Sans';
  src: url('./fonts/gillsans.ttf') format('truetype');
}

@font-face {
  font-family: 'nib-light-pro';
  src:
    url('./fonts/nib-light-pro.woff2') format('woff2'),
    url('./fonts/nib-light-pro.woff') format('woff'),
    url('./fonts/nib-light-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sharp Grotesk Bold';
  font-weight: 500;
  src:
    url('./fonts/SharpGroteskDBBlack23.woff2') format('woff2'),
    url('./fonts/SharpGroteskDBBlack23.woff') format('woff'),
    url('./fonts/SharpGroteskDBBlack23.ttf') format('truetype'),
    url('./fonts/SharpGroteskDBBlack23.otf') format('opentype');
}

@font-face {
  font-family: 'Sharp Grotesk 12';
  font-weight: 500;
  src:
    url('./fonts/SharpGroteskDBBold12.woff2') format('woff2'),
    url('./fonts/SharpGroteskDBBold12.woff') format('woff'),
    url('./fonts/SharpGroteskDBBold12.ttf') format('truetype'),
    url('./fonts/SharpGroteskDBBold12.otf') format('opentype');
}

@font-face {
  font-family: 'Sharp Grotesk 17';
  font-weight: 500;
  src:
    url('./fonts/SharpGroteskDBBold17.woff2') format('woff2'),
    url('./fonts/SharpGroteskDBBold17.woff') format('woff'),
    url('./fonts/SharpGroteskDBBold17.ttf') format('truetype'),
    url('./fonts/SharpGroteskDBBold17.otf') format('opentype');
}

:root {
  color-scheme: var(--dig-color-scheme);
}

html,
body,
#root {
  margin: 0;
  background-color: transparent;
}

body {
  background-color: var(--dig-color__background__subtle);
}
