.statusButton {
  background-color: transparent;
  outline: 1px solid var(--dig-color__border__subtle) !important;
  border: none;
}

.statusButtonSmall {
  height: 32px;
}

.statusButtonSmall :global(.dig-Button-content) {
  padding: 4px 8px;
}

.statusButtonSmall :global(.dig-Button-content) > span:first-of-type {
  margin-right: 6px;
}

.readButton :global(.dig-Text--size-large),
.statusButton :global(.dig-Text--size-large) {
  font-size: var(--type__body__standard--fontsize);
}

.readButton :global(.dig-Button-content),
.statusButton :global(.dig-Button-content) {
  height: 100%;
}

.iconButton :global(.dig-Button-icon-start-wrapper) {
  margin-right: 0 !important;
}

.square :global(.dig-Button-content) {
  padding: 8px;
}

.removePadding :global(.dig-Button-content) {
  padding: 0;
}
