/* .goalTable :global(.dig-Table-body .dig-Table-cell),
.goalTable :global(.dig-Table-header .dig-Table-cell) {
  border-left: none !important;
  border-right: none !important;
} */

.goalTable :global(.dig-Table-header .dig-Table-cell) {
  border-bottom: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.goalTable :global(.dig-Table-header .dig-Table-cell) {
  border-top: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.goalTable :global(.dig-Table-body .dig-Table-cell:first-of-type),
.goalTable :global(.dig-Table-header .dig-Table-cell:first-of-type) {
  border-left: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.goalTable :global(.dig-Table-body .dig-Table-cell:last-of-type) {
  border-right: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.goalTable :global(.dig-Table-body .dig-Table-row:last-of-type .dig-Table-cell) {
  border-bottom: 1px solid var(--dig-color__opacity__surface--state-2) !important;
}

.overflow {
  display: none;
  background: linear-gradient(90deg, transparent 0, var(--dig-color__background__subtle) 20px);
}

.overflowDark {
  background: transparent;
}

.overflow :global(.dig-Button),
.overflow :global(.dig-Button) {
  background: var(--dig-color__background__subtle);
}

.row :global(.dig-Button),
.row :global(.dig-Button) {
  background: var(--dig-color__background__subtle);
}

.row:active .overflow,
.row:active .overflow {
  background: transparent;
}

.row:hover .overflow {
  display: block;
}
