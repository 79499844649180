.close {
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 1000;
  background-color: var(--dig-color__primary-cta__base);
  color: var(--dig-color__primary-cta__on-base);
}

.desktopContent {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}

.landscape {
  max-width: calc(100% - 430px);
}

.portrait {
  max-width: calc(95% - 430px);
  right: 1px;
}

.message {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing__unit--5);
  overflow-y: auto;
  border-left: 1px solid var(--dig-color__border__subtle);
  box-sizing: border-box;
}

.message.landscape {
  max-height: 516px;
}

.message.portrait {
  max-height: 942px;
}

.flipButton {
  display: none;
  position: absolute;
  left: 50%;
  margin-left: -53.5px;
  bottom: -64px;
}

.modalOverridesLandscape {
  width: 95% !important;
  max-width: 1157px !important;
}

.modalOverridesPortrait {
  width: 95% !important;
  width: 1015px !important;
}

@media only screen and (max-width: 1080px) {
  .flipButton {
    display: block;
  }

  .landscape {
    max-width: 101%;
  }

  .portrait {
    max-width: 101%;
  }

  img.portrait {
    position: relative;
    right: 2px;
  }

  .image.flipped {
    display: none;
  }

  .message {
    display: none;
    width: 100%;
    min-height: 500px;
    min-width: initial;
    max-width: 100%;
  }

  .message.flipped {
    display: flex;
  }

  .message.landscape,
  .message.portrait {
    max-height: none;
  }

  .modalOverridesPortrait {
    width: 60% !important;
  }
}

@media only screen and (max-width: 600px) {
  .modalOverridesLandscape,
  .modalOverridesPortrait {
    width: 90% !important;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    height: auto;
  }
}

@media (prefers-color-scheme: light) {
  .modalOverrides :global(*) {
    /* stylelint-disable-next-line dig/var-recommended-colors */
    --dig-color__primary-cta__base: #1a1918 !important;
    /* stylelint-disable-next-line dig/var-recommended-colors */
    --dig-color__primary-cta__base--state-1: #393633 !important;
    /* stylelint-disable-next-line dig/var-recommended-colors */
    --dig-color__primary-cta__base--state-2: #4f4a46 !important;
  }
}

@media (prefers-color-scheme: dark) {
  .modalOverrides :global(*) {
    /* stylelint-disable-next-line dig/var-recommended-colors */
    --dig-color__primary-cta__base: #f7f5f2 !important;
    /* stylelint-disable-next-line dig/var-recommended-colors */
    --dig-color__primary-cta__base--state-1: #ebe9e6 !important;
    /* stylelint-disable-next-line dig/var-recommended-colors */
    --dig-color__primary-cta__base--state-2: #d3cec9 !important;
  }
}
