.formTitle > span:first-of-type,
label.formTitle {
    margin-bottom: 8px;
}

.formTextArea {
    padding: 12px;
}

.rightButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.leftButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.textInput {
    padding: 12px;
}

.dropzoneText{
    text-align: center;
}

.thumbnail {
    height: 64px;
    margin-right: 8px;
    border-radius: 8px;
}

.form {
    margin-top: 52px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 8px;
    padding-right: 36px;
}
