.wrapper {
  position: fixed;
  bottom: 16px;
  display: flex;
  justify-content: center;
  left: 0vw;
  width: 100%;
  z-index: 1;
}

.snackbar {
  margin-right: auto;
  margin-left: auto;
}
