.objectiveActionContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.keyResultContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.keyResultContainer:hover .keyResultButton,
.keyResultContainer:focus-within .keyResultButton {
  display: inline-block;
}

.keyResultTextArea {
  width: 100%;
  min-height: 56px !important;
  height: 56px;
  padding-right: 118px !important;
  padding-left: 42px !important;
  margin-bottom: -4px !important;
  padding-top: 16px !important;
  overflow: hidden;
}

.keyResultAvatar {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
}

.keyResultButton {
  position: absolute;
  right: 8px;
  top: 10px;
}

.keyResultButton {
  display: none;
}

.subTitleContainer {
  margin-bottom: 0 !important;
}

.sectionHeaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.sectionHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
