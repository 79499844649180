.avatar64 :global(.dig-Avatar) {
  height: unset;
  width: unset;
  height: 64px;
  width: 64px;
}

.avatar128 :global(.dig-Avatar) {
  height: unset;
  width: unset;
  height: 128px;
  width: 128px;
}

.avatar211 :global(.dig-Avatar) {
  height: unset;
  width: unset;
  height: 211px;
  width: 211px;
}

.avatar {
  user-select: none;
}

.avatar img {
  opacity: 0;
  transform: scale(1.04);
  transition:
    opacity 0.25s ease-in-out,
    transform 0.25s ease-in-out;
}

.avatarLoaded img {
  opacity: 1;
  transform: scale(1);
}

.deactivatedAvatarLoaded img {
  opacity: 0.5;
  transform: scale(1);
}

.moveUpXSmall img {
  margin-top: -2px;
}

.moveUpSmall img {
  margin-top: -4px;
}

.moveUpMedium img {
  margin-top: -6px;
}

.moveUpBig img {
  margin-top: -11px;
}

.wrapper {
  clip-path: var(--dig-avatar-clip-path, circle(50%));
  overflow: hidden;
  position: absolute;
  height: 128px;
  width: 145px;
  z-index: 1;
  left: -6px;
}

.ticker {
  position: absolute;
  width: 100%;
  background-color: var(--dig-color__primary__base);
  clip-path: polygon(0 0, 91% 0, 83% 100%, 23% 95%);
  bottom: 10px;
  z-index: 4;
  overflow: hidden;
  transform: rotate(-5deg);
}

.tickerText {
  white-space: nowrap;
  animation: scrollingText 4s linear infinite;
}

.tickerText span {
  color: var(--dig-color__background__base);
}

@keyframes scrollingText {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
