.wrapper {
  width: 100%;
  display: flex;
  padding-bottom: 48px;
  flex: 1;
}

.spinner {
  height: 168px;
  width: 168px;
  position: absolute;
  top: calc(var(--spacing__base_unit) * 6.5);
  right: calc(var(--spacing__base_unit) * 6.5);
  animation: spin 60s linear infinite;
}

.nav {
  display: flex;
  gap: var(--spacing__unit--3);
}

.content {
  position: relative;
  height: 100%;
  flex: 1;
  border-radius: 16px;
  padding: var(--spacing__unit--4) var(--spacing__unit--5);
}

@media only screen and (max-width: 860px) {
  .spinner {
    display: none;
  }
  .nav {
    justify-content: center;
  }
}

.link,
.link:visited,
.link:hover,
.link:active {
  color: inherit;
  text-decoration: none;
}

.title {
  margin-top: 106px;
  margin-bottom: var(--spacing__unit--1_5);
  text-align: center;
}

.titleText {
  font-family: 'nib-light-pro', var(--type__body__standard--fontfamily);
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: 72px;
  /* stylelint-disable-next-line dig/var-recommended-colors */
  color: white;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flavor {
  text-align: center;
  margin-top: var(--spacing__unit--1_5);
  margin-bottom: var(--spacing__unit--4);
}

@media only screen and (max-width: 860px) {
  .nav {
    justify-content: center;
  }
  .content {
    padding-left: var(--spacing__unit--2);
    padding-right: var(--spacing__unit--2);
  }
}
