:global(.dig-OverlayDrawer-overlay) {
  transform: none !important;
  top: 52px !important;
  position: fixed;
  left: unset !important ;
  right: 0 !important;
  height: calc(100vh - 52px) !important;
}

:global(.dig-Drawer-header) {
  padding: 20px 24px;
}

:global(.dig-Drawer-body) {
  padding: 16px 24px 24px;
  flex: 1;
}
