.key {
  -webkit-text-security: disc;
  text-security: disc;
  font-size: 22px;
  opacity: 0.4;
}

.row:hover .key {
  -webkit-text-security: none;
  text-security: none;
  font-size: inherit;
  opacity: 1;
}
