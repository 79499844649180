:root {
  /* stylelint-disable-next-line dig/var-recommended-colors */
  --box-shadow-color: rgba(30, 25, 25, 0.03);
}

.image {
  position: relative;
  border-radius: var(--spacing__unit--1);
  box-shadow:
    0px 1.25px 2.5px 0px var(--box-shadow-color),
    0px 2.5px 5px 0px var(--box-shadow-color),
    0px 5px 10px 0px var(--box-shadow-color),
    0px 10px 20px 0px var(--box-shadow-color),
    0px 10px 40px 0px var(--box-shadow-color);
  overflow: hidden;
  height: 300px;
  transition: all 0.2s ease-in-out;
}

.image:hover {
  cursor: pointer;
}

.emphasis:hover {
  transform: scale(1.1);
}

.image img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.4 / 1;
  transition: opacity 0.5s ease-in-out;
}

.landscape.default {
  width: 300px;
  height: 213px;
}

.portrait.default {
  height: 300px;
  width: 213px;
}

.landscape.small {
  width: 140px;
  height: 100px;
}

.portrait.small {
  height: 140px;
  width: 100px;
}

.skeleton {
  top: 0;
  left: 0;
  position: absolute;
  visibility: visible;
  background-size: initial;
}

@media only screen and (max-width: 545px) {
  .landscape.default {
    width: 247px;
    height: 176px;
  }

  .portrait.default {
    width: 176px;
    height: 247px;
  }
}
