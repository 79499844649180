.teamsTreeItemContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
}

.teamsTreeSearchItem {
  padding-left: 40px;
  margin-top: 4px;
}
.teamsTreeSearchItem > span {
  height: 24px;
  display: flex;
  align-items: center;
}
