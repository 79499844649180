.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form textarea {
  flex: 1;
  border: none;
  font-family: 'nib-light-pro', var(--type__body__standard--fontfamily);
  font-size: 24px;
  font-weight: 600;
  line-height: 41px;
  padding: 0;
  overflow: none;
  letter-spacing: 0.5px;
}

.form textarea::placeholder {
  color: var(--dig-color__text__subtle);
}

.toAndFrom {
  padding: var(--spacing__unit--3) 0 var(--spacing__unit--2);
}

.chips {
  gap: var(--spacing__unit--1);
}

.addRecipients {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--2);
}

.toAndFromContainer {
  min-height: 118px;
}

.form :global(.dig-TextInputContainer) {
  border: 0;
  padding-left: 0;
}

.form :global(.dig-TextArea:focus),
.form :global(.dig-TextInputContainer:focus-within) {
  box-shadow: none !important;
}

.button {
  font-family: 'Gill Sans', var(--type__body__standard--fontfamily) !important;
  letter-spacing: 1.68px;
  text-transform: uppercase;
  margin-top: var(--spacing__unit--1);
}
