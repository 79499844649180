.dialLock svg > g:first-of-type {
  fill: var(--dig-color__highlight__purple--bold);
}

.puzzlePieces svg > path:first-of-type,
.stackMini svg > path:first-of-type {
  fill: var(--dig-color__highlight__blue--bold);
}

.racingFlag svg > path:first-of-type {
  fill: var(--dig-color__highlight__blue);
}

.laptop svg > path:first-of-type {
  fill: var(--dig-color__highlight__purple--bolder);
}
