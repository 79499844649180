/* stylelint-disable dig/var-recommended-colors */

.brandSharp1276 {
  font-family: 'Sharp Grotesk 12', 'Sharp Grotesk 23', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 74px;
  line-height: 74px;
  letter-spacing: 4px;
}

.brandSharp1776 {
  font-family: 'Sharp Grotesk 17', 'Sharp Grotesk 23', Helvetica, sans-serif;
  font-weight: 700;
  font-size: 74px;
  line-height: 74px;
}

.brandSharp {
  font-family: 'Sharp Grotesk Bold', 'Sharp Grotesk 23', Helvetica, sans-serif;
  font-weight: 700;
  font-size: 45px;
  line-height: 45px;
}

.closeButton {
  position: absolute;
  top: 4px;
  right: 4px;
}

.buttonLink :global(.dig-Button > span) {
  border-color: rgba(0, 0, 0, 0.3);
}

.buttonLink :global(.dig-Button:hover > span) {
  border-color: var(--dig-color__text__base);
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
}

.marqueeSlider {
  display: inline-block;
  will-change: transform;
  animation: marquee 350s linear infinite;
  letter-spacing: 2px;
}

@keyframes marquee {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-50%, 0, 0);
  }
}

/* on <= 1200px width */
@media (max-width: 1200px) {
  .brandSharp1776 {
    font-size: 40px;
    line-height: 40px;
  }

  .brandSharp1276 {
    font-size: 40px;
    line-height: 40px;
  }
}
