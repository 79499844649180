.drawerHeader {
  min-height: auto;
}

.drawerContainerSlider {
  overflow: hidden;
  width: 800px;
}

.drawerContainerSlides {
  display: flex;
  transition: transform var(--duration__125) var(--easing__enter);
  width: 1600px; /* 475px x 2 slides */
}

.drawerContainerSlide0 {
  transform: translateX(0);
}

.drawerContainerSlide1 {
  transform: translateX(-50%);
}

.drawerContainerSlide {
  height: 95vh;
  width: 800px;
}

.parentGoalRow:hover {
  background-color: var(--dig-color__opacity__surface--state-1);
  cursor: pointer;
}

.laptopMini svg > path:first-of-type {
  fill: var(--dig-color__highlight__blue--bold) !important;
}

.commentsMini svg > g > path:first-of-type {
  fill: var(--dig-color__highlight__blue--bold) !important;
}
