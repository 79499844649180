.tooltip :global(.dig-Tooltip-content) {
  padding: 8px 0 !important;
}

.error:hover {
  background-color: var(--dig-color__alert__surface--state-1);
}

.chevron {
  opacity: 0;
}

:global(.dig-Table-row):hover .chevron {
  opacity: 1;
}
