.input {
  display: block;
}

.text {
  margin-left: 12px;
}

.editor {
  display: flex;
  align-items: center;
  position: absolute;
  gap: 8px;
  top: 0;
  left: 0;
  z-index: 1;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: var(--dig-color__background__base);
  color: var(--dig-color__border__base);
  border-radius: 8px;
  transition: opacity 0.5s;
  will-change: transform;
}

.link:global(.dig-Link:focus) {
  margin-left: 12px;
  padding: 0;
}

.view {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 12px 16px;
  align-items: center;
  gap: 8px;
}

.link {
  flex: 1;
}
