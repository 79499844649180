/* Title */
:global(.dig-Title) {
  font-family: var(--type__body__standard--fontfamily);
  font-weight: var(--type__body__large--fontweight_strong);
  font-size: 22px;
  line-height: 28px;
}

/* Text */
:global(.dig-Text--size-standard.dig-Text--isBold) {
  line-height: 20px;
}

/* Links */
:global(.dig-Link--primary) {
  color: var(--dig-color__text__base);
}
