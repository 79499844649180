/* .wrapper {
  background: var(--dig-color__background__base);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
} */

/* .container {
  display: flex;
  background: var(--dig-color__background__base);
  height: 100%;
  width: 100%;
  overflow-y: hidden;
} */

.home {
  /* stylelint-disable-next-line dig/var-recommended-colors */
  background: radial-gradient(circle, #f8f6f3a1 70%, transparent 70%) no-repeat;
  background-position: center 500px;
  background-size: 18000px 18000px;
  position: fixed;
  left: 0;
  top: -250px;
  height: 200vh;
  width: 100vw;
}

.homeDark {
  /* stylelint-disable-next-line dig/var-recommended-colors */
  background: radial-gradient(circle, #202020a1 70%, transparent 70%) no-repeat;
  background-position: center 500px;
  background-size: 18000px 18000px;
  position: fixed;
  left: 0;
  top: -250px;
  height: 200vh;
  width: 100vw;
}

.content {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}

.includePadding {
  background: var(--dig-color__background__base);
  padding-left: var(--spacing__unit--4);
  padding-right: var(--spacing__unit--4);
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--dig-color__utility__overlay);
  z-index: 10;
  padding-top: 99px;
  justify-content: center;
  display: flex;
  z-index: 12;
}

.searchWrapper {
  max-width: 820px;
  width: 100%;
  height: 0px;
}

@media only screen and (max-width: 860px) {
  .searchWrapper {
    width: 90%;
  }
}
