.typeaheadRow {
  min-height: 64px;
}

.searchMenuSelectedContainer {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--dig-color__opacity__surface);
}

.selectedComponentContent {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
}

.selectedComponentText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.title {
  max-width: 420px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.goalSearchResultsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 8px;
}

.goalSearchResultItemContainer {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--dig-color__border__subtle);
}

.goalSearchResultItemContainer:hover {
  background: var(--dig-color__opacity__surface);
  cursor: pointer;
}

.noGoalTextContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.noGoalButton {
  color: var(--dig-color__text__subtle);
}

.noGoalSearchResultItemContainer {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--dig-color__border__subtle);
}

.groupedGoals {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}
