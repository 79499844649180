.objectiveActionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.objectiveActionSectionMainTitle {
  display: flex;
  align-items: center;
  font-size: var(--type__body__large--fontsize);
  size: 16px;
}

.objectiveActionItemContainer {
  display: flex;
  max-width: 620px;
  width: 100%;
  padding: 16px 18px 16px 12px;
  align-items: flex-start;
  gap: 5px;
  border-radius: 8px;
  border: 1px solid var(--dig-color__border__subtle);
}

.objectiveActionItemTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
}

.objectiveActionItemIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: var(--dig-color__border__base);
}

.keyResultActionContainer {
  display: flex;
  padding: 24px 32px 28px 32px;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--dig-color__border__subtle);
}

.keyResultActionItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.addKeyResultButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancelButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.strategySelectOptions {
  width: 100%;
}

.typeaheadResultsContainer {
  border: 1px solid var(--dig-color__border__subtle);
  background: var(--dig-color__background__base);
  border-radius: 8px;
  padding-bottom: 0px;
}

.typeaheadResultsContainer :global(.dig-Typeahead-results) {
  max-height: 270px;
  overflow-y: auto;
}

.typeaheadResultsContainer :global(.dig-Typeahead-results) > *:first-child {
  position: sticky;
  top: 0;
  background: var(--dig-color__background__base);
  margin: 0;
  border-radius: 8px;
  padding: 12px;
  z-index: 1;
}

.strategyTextContainer {
  display: flex;
  justify-content: flex-start;
  max-width: 620px;
  width: 100%;
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: 8px;
}

.objectiveActionItemIcon {
  color: var(--dig-color__text__subtle);
}

.strategySelectOptions :global(.dig-TextInput-input),
.strategySelectOptions :global(.dig-TextInputContainer) {
  color: var(--dig-color__text__subtle) !important;
  display: flex;
  align-items: center;
}

.strategySelectOptions :global(.dig-TextInput-chips-container > *) {
  font-size: var(--type__body__standard--fontsize);
}

.keyResultContainer {
  border-top: 1px solid var(--dig-color__border__subtle);
  padding-top: 24px;
}
