.selectNav {
  display: none;
}

.container {
  height: calc(100vh - 240px);
  overflow: auto;
}
/* 
.container {
  min-width: 290px;
  width: 290px;
  overflow: auto;
} */

.list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--0_5);
}

@media only screen and (max-width: 860px) {
  .selectNav {
    display: initial;
  }
  .container {
    display: none;
  }
}
