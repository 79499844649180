.searchGlobal {
  padding: 18px;
  border-radius: 10px;
}

.searchContainer {
  max-width: 820px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--dig-color__background__base);
}

.searchInputContainer {
  width: 100%;
}

.searchInput {
  margin-top: 30px;
}

@media only screen and (max-width: 860px) {
  .searchFiltersKeyboard {
    display: none;
  }
}
