.container {
  display: flex;
  gap: 16px;
}

.column {
  width: 50%;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

@media (max-width: 860px) {
  .container {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
}

.puzzlePieces svg > path:first-of-type {
  fill: var(--dig-color__highlight__blue--bold);
}
