.peopleSearchContainer {
  margin-bottom: -16px;
  width: 100%;
}

.peopleTitle {
  margin-top: 48px;
  display: inline-block;
}

.profileList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 20px;
}

.showMoreButton {
  margin-top: 16px;
  color: var(--dig-color__border__bold);
}

@media only screen and (max-width: 860px) {
  .profileList {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
    width: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--dig-color__utility__overlay);
  z-index: 10;
  padding: 96px 16px 0px 156px;
  justify-content: center;
  display: flex;
}
