.tooltip {
  width: 315px;
}

.tooltip :global(.dig-Tooltip-content) {
  padding: 0 !important;
  border: 1px solid var(--dig-color__border__subtle) !important;
  width: 100% !important;
}

.tooltip :global(.dig-Tooltip-arrow) {
  border-top: 1px solid var(--dig-color__border__subtle) !important;
  border-left: 1px solid var(--dig-color__border__subtle) !important;
  box-shadow: none !important;
  z-index: 12;
}

.tooltipEmpty {
  width: 315px;
}

.tooltipEmpty :global(.dig-Tooltip-content) {
  padding: 0 !important;
  border: 1px solid var(--dig-color__border__subtle) !important;
  width: 100% !important;
}

.tooltipEmpty :global(.dig-Tooltip-arrow) {
  border-top: 1px solid var(--dig-color__border__subtle) !important;
  border-left: 1px solid var(--dig-color__border__subtle) !important;
  box-shadow: none !important;
}

.commentEditTextArea {
  width: 100%;
  height: 24px;
  min-height: 24px !important;
  overflow: hidden;
  border: none;
}
