/* stylelint-disable dig/var-recommended-colors */
.editor-text-code {
  background-color: var(--dig-color__background__raised);
  border: 1px solid var(--dig-color__border__subtle);
  color: var(--dig-color__text__subtle);
  padding: 2px 0.25rem 1px;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 92%;
}

.editor-code {
  background-color: var(--dig-color__background__raised);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: var(--dig-color__text__subtle);
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: var(--dig-color__text__subtle);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}
