.error {
  text-align: center;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background: var(--dig-color__background__base);
  height: 100%;
}

.image {
  margin-bottom: 24px;
  width: 300px;
}

.title {
  max-width: 960px;
  margin: 0;
}

.description {
  max-width: 540px;
  margin: 24px 0;
}

@media only screen and (max-width: 545px) {
  .error {
    padding: 48px 24px;
  }
}
