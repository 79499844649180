.loadingPost {
  display: flex;
  width: 680px;
  flex-direction: column;
  flex: 1 0 0;
  gap: 16px;
  padding: 0px 80px 36px 80px;
  margin-top: 40px;
}

.loadingBox {
  width: 340px;
  height: 88px;
  margin-bottom: 16px;
}
