.keyResultActionContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
}

.keyResultActionItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.addKeyResultButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createGoalContainer {
  display: flex;
  padding: 0px var(--spacing-unit-2, 16px);
  align-items: flex-start;
  margin-top: -16px;
}

.createGoalWrapper {
  display: flex;
  padding: var(--spacing-unit-2, 16px) 0px;
  align-items: center;
  gap: var(--spacing-unit-15, 12px);
}

.createGoalTexts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.createGoalTexts :hover {
  cursor: pointer;
}
