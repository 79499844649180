.container {
  width: 100%;
  height: 254px;
  position: relative;
  overflow: hidden;
  background-size: 24px 24px;
  background-position: -12px -12px;
}

.adjustable {
  cursor: grab;
}

.nodeCard {
  text-decoration: none;
  display: flex;
  font-family: var(--__bodyFontStack);
  padding: 15px;
  overflow: visible;
  justify-content: center;
  gap: 12px;
  border-radius: 12px;
  background: var(--dig-color__background__base);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  border: 1px solid var(--dig-color__border__subtle);
  transition: background-color 0.175s;

  outline-color: var(--dig-color__utility__focus-ring);
  outline-offset: 1px;

  height: 100%;
  box-sizing: border-box;
}

.nodeCard.selected {
  border: 2px solid var(--dig-color__highlight__purple);
  box-shadow: 0 4px 12px 0 var(--dig-color__highlight__purple);
}

.nodeCard.focused {
  border: 2px solid var(--dig-color__primary__base);
  box-shadow: 0 4px 12px 0 var(--dig-color__selection__surface--state-2);
}

.nodeCard:active {
  box-shadow: none;
  opacity: 1;
}

.nodeCardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
}

.nodeCardTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--dig-color__text__base);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nodeCardSubtitle {
  font-size: 12px;
  line-height: 20px;
  color: var(--dig-color__text__subtle);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.adjustable:active {
  cursor: grabbing;
}

/* Profile view */

.profile .nodeCard {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 15px 11px;
}

.profile .nodeCardContent {
  overflow: inherit;
}

.profile .nodeCardContent {
  text-align: center;
}

.profile .nodeCardSubtitle {
  display: -webkit-box;
  line-clamp: 3;
  line-height: 16px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: inherit;
}

.overflowedRoleLevel {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--dig-color__background__base);
  transition: background-color 0.175s;
}

.tooltip {
  position: absolute;
  display: none;
  padding: 12px;
  font-family: var(--__bodyFontStack);
  border-radius: 4px;
  line-height: 16px;
  pointer-events: none;
  z-index: 999;
  font-size: 12px;
}

.nodeCard:hover,
.nodeCard:hover .overflowedRoleLevel {
  background-color: var(--dig-color__background__subtle);
}
