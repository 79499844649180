.container :global(.dig-List) {
  max-height: calc(80vh - 140px);
  overflow-y: auto;
}

.header {
  padding: var(--spacing__unit--4) var(--spacing__unit--4) 2px var(--spacing__unit--4);
}

.title {
  padding-bottom: 4px;
}

.text {
  padding-top: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.supportingText {
  margin-top: 1;
}

.collected {
  margin-right: 1;
  font-weight: var(--type__body__standard--fontweight_strong);
}

.requestBadge {
  font-size: 12px !important;
  margin-left: 4;
}

.chevron {
  color: var(--dig-color__border__base);
}
