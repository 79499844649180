.projectLink {
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: 8px;
}

.projectLink :global(.dig-TextInputContainer):first-of-type {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.projectLink
  :global(.dig-TextInputContainer):last-of-type:not(
    .projectLink :global(.dig-TextInputContainer):only-of-type
  ) {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.projectLink :global(.dig-TextInputContainer) {
  border: none;
}

.projectLink:has(:global(.dig-TextInputContainer--isInvalid)) {
  border: 1px solid var(--dig-color__alert__base);
}

.projectLink :global(.dig-Select-trigger) {
  border: none;
}
