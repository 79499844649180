:global(.dig-Modal) {
  margin: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

:global(.dig-Modal-header) {
  padding: 20px 24px;
  border-bottom: 1px solid var(--dig-color__border__subtle);
}

:global(.dig-Modal-content) {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

:global(.dig-Modal-body) {
  padding: 24px;
  flex-grow: 1;
  overflow-y: auto;
}

:global(.dig-Modal-title) {
  margin-top: 0px !important;
}

:global(.dig-Modal-body) :global(.dig-FormRow):last-of-type {
  margin-bottom: 0;
}
