/* Subtle Small theme */

.title {
  color: var(--dig-color__text__subtle);
  font-family: var(--type__body__standard--fontfamily);
  font-size: var(--type__body__standard--fontsize);
  font-weight: var(--type__body__standard--fontweight_strong);
  line-height: var(--type__body__standard--lineheight);
  -webkit-font-smoothing: antialiased;
}

.text {
  color: var(--dig-color__text__subtle);
  font-family: var(--type__body__standard--fontfamily);
  font-size: var(--type__body__standard--fontsize);
  font-weight: var(--type__body__standard--fontweight);
  line-height: var(--type__body__standard--lineheight);
  -webkit-font-smoothing: antialiased;
}

.bold {
  color: var(--dig-color__text__base);
  font-weight: var(--type__body__standard--fontweight_strong);
  line-height: 20px;
}

.link {
  text-decoration: underline;
  color: inherit;
  font-weight: inherit;
}

.link:hover {
  text-decoration: none;
}
