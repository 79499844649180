.newsPostRight {
  display: grid;
  flex-direction: column;
}

.title {
  margin-top: 32px;
}

.info {
  display: flex;
  align-content: center;
  gap: 8px;
  margin-bottom: 24px;
  margin-top: 12px;
}

.draftChip {
  background: var(--dig-color__selection__surface--state-1);
}
