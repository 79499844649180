.container {
  width: 100%;
  box-sizing: border-box;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: var(--spacing__unit--2);
}

.action {
  margin-left: auto;
}
