.container {
  display: flex;
  gap: var(--spacing__unit--4);
}

.emptyText {
  padding-left: var(--spacing__unit--1_5);
  padding-bottom: var(--spacing__unit--2);
}

.selectNav {
  display: none;
}

.notification {
  margin-left: auto;
  background-color: var(--dig-color__alert__base);
  border-radius: 4px;
  width: var(--spacing__unit--1);
  height: var(--spacing__unit--1);
}

@media only screen and (max-width: 860px) {
  .container {
    flex-direction: column;
  }

  .selectNav {
    display: initial;
  }
}
