.headerLine {
  border-bottom: 1px solid var(--dig-color__border__subtle);
}

.updateRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  gap: 16px;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-right: 18px;
}

.statusOptionOverride {
  flex-direction: row-reverse !important;
}

.statusSelectOptionOverride svg {
  height: 24px;
  width: 24px;
}

.tabGroup {
  padding: 0 0 0 0;
}

.tabGroup :global(.dig-Tabs-tab) {
  padding: 0 0 0 0;
}
