.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.button {
  font-family: 'Gill Sans', var(--type__body__standard--fontfamily) !important;
  letter-spacing: 1.68px;
  text-transform: uppercase;
  margin-top: var(--spacing__unit--1);
}

.message {
  font-family: 'nib-light-pro', var(--type__body__standard--fontfamily);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 41px;
  white-space: pre-line;
  height: 100%;
  width: 100%;
  letter-spacing: 0.5px;
}

.people {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1);
  padding-top: var(--spacing__unit--2);
}
