.settingsList > * {
  border-bottom: 1px solid var(--dig-color__border__subtle);
}

.settingsList > *:last-child {
  border: 0;
}

.settingsListItem {
  align-items: normal !important;
  padding: 16px 0px 26px 0px;
}

.extra {
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  transition:
    max-height 0.15s,
    opacity 0.15s;
}

.expanded {
  margin: -16px 0 26px 32px;
  visibility: visible;
  max-height: 300px;
  opacity: 1;
}

.workingWithMe {
  max-height: 300px;
}

.fingerprint svg > g:nth-child(2) > path:nth-child(1) {
  fill: var(--dig-color__primary__surface--state-1);
}
