.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing__unit--2);
}

.headerImage {
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
}

.container :global(.dig-List) {
  max-height: calc(80vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
}

.container > * {
  width: 100%;
  box-sizing: border-box;
}

.titleHeader {
  opacity: 0;
  display: block;
}

.titleHeader.visible {
  opacity: 1;
}

.header {
  position: relative;
  padding: var(--spacing__unit--2);
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid var(--dig-color__border__subtle);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.awardedToAtLeastOne {
  cursor: pointer;
}

.awarded span {
  vertical-align: super;
  padding-left: 2px;
}

.body {
  padding: var(--spacing__unit--1) var(--spacing__unit--4);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1_5);
}

.nameAndDescription {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1);
}

.buttons {
  margin-bottom: var(--spacing__unit--1);
}

.footer {
  text-align: center;
  padding: 19px var(--spacing__unit--3);
  border-top: 1px solid var(--dig-color__border__subtle);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dig-color__utility__overlay);
  opacity: 0.03;
  pointer-events: none;
}

.nominateBody {
  display: flex;
  flex-direction: column;
  padding: var(--spacing__unit--1) var(--spacing__unit--3) var(--spacing__unit--2);
}

.nominateFooter {
  padding: var(--spacing__unit--2) var(--spacing__unit--3);
}

.nominationTitle {
  padding-bottom: var(--spacing__unit--0_5);
}

.nominationExplaination {
  padding-bottom: var(--spacing__unit--1);
}

.container textarea {
  margin-top: var(--spacing__unit--1_5);
}

.buttonGroup {
  gap: 10px;
  justify-content: center;
}

.badgeDescription {
  padding: var(--spacing__unit--1_5) var(--spacing__unit--1) 0;
}
