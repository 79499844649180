.hammock g path:first-of-type,
.cabinet svg > path:first-of-type {
  fill: var(--dig-color__identity__purple);
}

.hammockDark g path:first-of-type,
.cabinetDark svg > path:first-of-type {
  fill: var(--dig-color__accent__purple);
}

.upcomingDark {
  /* stylelint-disable-next-line dig/var-recommended-colors */
  background: #9b640026;
}
.upcoming {
  background: var(--dig-color__background__subtle);
}
