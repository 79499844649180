.link {
  text-decoration: none;
  color: var(--dig-color__text__base);
}

.link:hover {
  text-decoration: none;
}

.monochromatic {
  color: inherit;
}

.link:focus {
  color: var(--dig-color__text__subtle);
}

.underline {
  text-decoration: underline;
}

.link.focused {
  border-color: var(--dig-color__secondary__base);
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  outline: none;
  border-radius: 8px;
}

.linkStyledButton:hover {
  text-decoration: none;
}

.link.showUnderlineOnHover:hover {
  text-decoration: underline;
}

.bold {
  font-weight: var(--type__body__standard--fontweight_strong);
}

.linkStyledButton {
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
}
