.strategyContainer {
  display: flex;
  min-height: 84px;
}

.strategyContainer:hover .strategyLinkColumn {
  display: block;
}

.strategyColumn {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.strategyColumn:nth-child(2),
.strategyColumn:first-child {
  justify-content: center;
}
.strategyColumn:nth-child(2) {
  align-self: center;
}

.strategyColumn:last-child {
  flex-grow: 1;
  justify-content: flex-start;
}

.strategyIcon {
  width: 80px;
  height: auto;
}

.strategyTextColumn {
  margin: 0;
}

.strategyTextColumn,
.strategyLinkColumn {
  flex-grow: 1;
}

.strategyTextColumn p,
.strategyTextColumn span {
  margin: 0;
  padding: 0;
}

.strategyTextColumn span,
.strategyTextColumn span a,
.strategyLinkColumn span {
  color: var(--dig-color__text__subtle);
}

.strategyLinkColumn {
  display: none;
  justify-self: end;
}

.strategyLinkColumn span svg {
  position: relative;
  top: 4px;
}

@media only screen and (max-width: 860px) {
  .strategyTextColumn p {
    font-size: var(--type__body__small--fontsize);
    line-height: 18px;
  }

  .strategyContainer {
    height: auto;
  }
}
