.container {
  position: relative;
}

.image {
  object-fit: contain;
}

.skeleton {
  top: 0;
  left: 0;
  position: absolute;
  visibility: visible;
  background-size: initial;
}
