.profileReportsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.profileReportsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin-top: 12px;
  width: 100%;
}

@media only screen and (max-width: 860px) {
  .profileReportsList {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
}
