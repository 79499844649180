.button {
  visibility: hidden;
}

.row:hover .button {
  visibility: visible;
}

.chip {
  visibility: visible;
}

.row:hover .chip {
  visibility: hidden;
}
