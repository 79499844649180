.backButton {
  margin-bottom: 24px;
}

.titleContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 16px;
}

.plugMini svg > g > path:first-of-type {
  fill: var(--dig-color__highlight__blue--bold) !important;
}
