:global(.dig-Avatar) {
  background-color: transparent;
}

:global(.dig-Avatar-text) {
  font-family: var(--type__body__standard--fontfamily) !important;
}

:global(.dig-Facepile) {
  margin-left: 8px;
}

:global(.dig-Facepile-item) {
  margin-left: -8px !important;
}

:global(.dig-Avatar) {
  margin: 0;
}
