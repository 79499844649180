.rows {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing__unit--4);
  justify-content: center;
  align-items: flex-end;
  max-width: 1400px;
  margin: 0 auto;
}

.item {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing__unit--2);
  justify-content: flex-end;
}

.item.filtered {
  display: none;
}

@media only screen and (max-width: 860px) {
  .rows {
    gap: var(--spacing__unit--5);
  }
  .item {
    min-height: auto;
  }
}
