:global(.dig-TextInputContainer--large:hover),
:global(.dig-TextArea:hover),
:global(.dig-Select-trigger:hover),
:global(.dig-TextArea):not(:global(.dig-TextArea--isInvalid)),
:global(.dig-Select:not(.dig-Select--isInvalid) .dig-Select-trigger),
:global(.dig-TextInputContainer):not(:global(.dig-TextInputContainer--isInvalid)),
:global(.dig-TextInputContainer--large):not(:global(.dig-TextInputContainer--isInvalid)),
:global(.dig-TextInput-input) {
  border-color: var(--dig-color__border__subtle) !important;
}

:global(.dig-Select-trigger) {
  height: 24px !important;
}

:global(.dig-FormLabel) {
  font-size: var(--type__body__large--fontsize);
  line-height: var(--type__body__large--lineheight_label);
  margin-bottom: 8px;
}

:global(.dig-FormHelperText) {
  margin-bottom: 8px;
}

:global(.dig-FormRow) :global(.dig-TextArea),
:global(.dig-FormRow) :global(.dig-TextInputContainer),
:global(.dig-FormRow) :global(.dig-Select-trigger) {
  font-size: var(--type__body__large--fontsize);
  line-height: var(--type__body__large--lineheight_label);
  padding: 16px;
  min-height: 56px;
}

:global(.dig-TextInputAccessory) {
  height: 24px;
}

:global(.dig-Select-trigger-icon) {
  margin-top: 10px;
}

:global(.dig-Dropzone-container) {
  border-width: 1px;
}

:global(.dig-ClickOutside) {
  width: 100%;
}

:global(.dig-ClickOutside) :global(.dig-TextInputAccessory--start) {
  margin-right: 8px;
}
