.collapsedLeftNav {
  z-index: 11;
  position: fixed;
  top: 52px;
  left: 0;
  bottom: 0;
  padding: 24px 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32px;
  overflow: hidden;
  border-right: 1px solid var(--dig-color__border__subtle);
  background-color: var(--dig-color__background__base);
  transition: width var(--duration__125) var(--easing__transition);
}

.expanded {
  width: 200px;
}

.categoryBorder {
  position: relative;
}

.categoryMenu {
  font-size: 8px;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: 0.96px;
  opacity: 0;
  position: absolute;
  bottom: 11px;
  left: 6px;
  transition: opacity var(--duration__125) var(--easing__transition);
}

.categoryBorder::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  transform: translateX(-50%);
  height: 1px;
  background-color: var(--dig-color__border__subtle);
  transition: opacity var(--duration__125) var(--easing__transition);
}

.expanded .categoryMenu {
  opacity: 1;
}

.expanded .categoryBorder::before {
  opacity: 0;
}

@media only screen and (max-width: 860px) {
  .collapsedLeftNav {
    overflow: hidden;
  }

  .expanded {
    width: 32px;
  }

  .expanded .categoryMenu {
    opacity: 0;
  }

  .expanded .categoryBorder::before {
    opacity: 1;
  }
}

.itemLink.itemLink {
  color: var(--dig-color__text__subtle);
  white-space: nowrap;
}

.itemLink.itemLink:hover,
.itemLink.activeItemLink {
  color: var(--dig-color__text__base);
}
