.dashboardsGoalsOverviewGraph {
  align-items: center;
  display: flex;
}

.dashboardsGoalsOverviewSegment {
  cursor: pointer;
}

.dashboardsGoalsOverviewSegmentRoundedLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.dashboardsGoalsOverviewSegmentRoundedRight {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dashboardsGoalsOverviewTooltip {
  display: none;
  background-color: var(--dig-color__background__base);
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: 8px;
  padding: 12px;
  position: absolute;
  z-index: 100;
}

.dashboardsGoalsOverviewGraph:hover .dashboardsGoalsOverviewTooltip {
  display: block;
}

.dashboardsHeatmapList {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 16px;
}

@media (max-width: 1320px) {
  .dashboardsHeatmapList {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 680px) {
  .dashboardsHeatmapList {
    grid-template-columns: 1fr;
  }
}

.dashboardsHeatmapCell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 14px;
}

.dashboardsHeatmapCell:hover {
  cursor: pointer;
}

.dashboardsHeatmapTwoCells {
  grid-row: span 2;
}

.dashboardsHeatmapCellGraph {
  height: 0;
  transition: height var(--duration__125) var(--easing__linear);
  visibility: hidden;
}

.dashboardsHeatmapCellGraphShiftUp {
  height: 15px;
  visibility: visible;
}

.dashboardsTeamNavMenu {
  background-color: var(--dig-color__background__base);
  min-width: 350px;
  padding: 16px;
}

.dashboardsPeopleList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.dashboardsPeopleListScroll {
  max-height: 515px;
  overflow-y: auto;
}

.dashboardsGoalsListScroll {
  max-height: 535px;
  overflow-y: auto;
}

.dashboardsGoalsListHeader {
  background: var(--dig-color__background__base);
  position: sticky;
  top: 0;
  z-index: 2;
}

@media only screen and (max-width: 860px) {
  .dashboardsPeopleList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 680px) {
  .dashboardsPeopleList {
    grid-template-columns: 1fr;
  }
}
