.emptyStateContainer {
  width: 100%;
  height: 560px;
  padding: 24px;
  border-radius: var(--dig-spacing__micro__small);
  border: 1px solid var(--dig-color__border__subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  box-sizing: border-box;
}

.blankContainer {
  height: 560px;
  padding: 24px;
  margin-top: 12px;
}
