/* News theme */

.title {
  color: var(--dig-color__text__base);
  font-family: var(--type__body__standard--fontfamily);
  font-size: 24px;
  font-weight: var(--type__body__standard--fontweight_strong);
  line-height: 32px;
  -webkit-font-smoothing: antialiased;
}

.text {
  color: var(--dig-color__text__base);
  font-family: var(--type__body__standard--fontfamily);
  font-size: 16px;
  font-weight: var(--type__body__standard--fontweight);
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
}

h1 .text {
  font-size: 24px;
  line-height: 32px;
}

.bold {
  color: var(--dig-color__text__base);
  font-weight: var(--type__body__standard--fontweight_strong);
  line-height: 20px;
}

.link {
  text-decoration: underline;
  color: inherit;
  font-weight: inherit;
}

.link:hover {
  text-decoration: none;
}
