.backButton {
  position: absolute;
  margin-left: -40px;
}

.formTitle > span:first-of-type,
label.formTitle {
  margin-bottom: 8px;
}

.chip {
  margin-left: 8px;
}

.uploadRemoveButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.dropzoneText {
  text-align: center;
}

.addCategory {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--dig-color__background__base);
  padding-top: 16px;
  padding-bottom: 8px;
}
