.title {
    margin-top: 32px;
}
  
.info {
    display: flex;
    align-content: center;
    gap: 8px;
    margin-bottom: 24px;
    margin-top: 12px;
}
  