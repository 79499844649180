.commentInput {
  display: flex;
  border-top: 1px solid var(--dig-color__border__subtle);
}

.commentInput :global(.dig-TextInputContainer) {
  border: none !important;
}

.iconAvatar {
  position: absolute;
  left: 16px;
  top: 16px;
}

.drawerIconAvatar {
  position: absolute;
  left: 16px;
  top: 16px;
}

.iconButton {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}

.commentInputTextArea {
  width: 100%;
  min-height: 52px !important;
  height: 52px;
  overflow: hidden;
  border: none;
  padding-right: 36px !important;
  padding-left: 44px !important;
  padding-top: 16px !important;
  max-height: '280px';
  overflow-y: auto;
}

.drawerCommentInputTextArea {
  width: 100%;
  min-height: 52px !important;
  height: 52px;
  overflow: hidden;
  padding-right: 36px !important;
  padding-left: 50px !important;
  padding-top: 14px !important;
  max-height: '280px';
  overflow-y: auto;
}

.commentInputBox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3px;
}

.drawerCommentInputBox {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 3px;
}

.drawerCommentReplyBox {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 3px;
}

.drawerCommentReplyBox :global(.dig-TextInputContainer) {
  border: none !important;
}

.drawerCommentReplyTextArea {
  width: 100%;
  min-height: 52px !important;
  height: 52px;
  overflow: hidden;
  padding-right: 36px !important;
  padding-left: 16px !important;
  padding-top: 14px !important;
  max-height: '280px';
  overflow-y: auto;
  border: none;
}

.commentInput :global(.dig-TextArea) {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.commentInputEmpty :global(.dig-TextArea) {
  border-radius: 12px !important;
}
