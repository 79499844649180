.progressWrapper {
  display: inline-flex;
  align-items: center;
}

.progressBar {
  margin-right: 8px;
  width: 25px;
  height: 8px;
  border-radius: 32px;
  z-index: 1;
  overflow: hidden;
}

.progressBar > div {
  height: 100%;
  background-color: var(--dig-color__folder__closed);
}

.accessoryRight {
  padding-right: 4px;
}

@media only screen and (max-width: 860px) {
  .accessoryRight {
    display: none;
  }
  .progressWrapper {
    display: flex;
  }
}
