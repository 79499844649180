.container {
  position: relative;
  width: 100%;
  transition: transform var(--duration__surface) ease;
}

.drawer {
  overflow: auto;
  position: fixed;
  top: 0;
  right: -475px;
  width: 475px;
  top: 52px;
  height: calc(100% - 52px);
  border-left: 1px solid var(--dig-color__border__subtle);
  background-color: var(--dig-color__background__base);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  transition: right var(--duration__surface) ease;
  z-index: 10;
}

.open .drawer {
  right: 0;
  opacity: 1;
}

.open .container.slide {
  transform: translateX(-205px);
}

@media (max-width: 860px) {
  .container {
    margin-left: 0;
    width: 100%;
    transform: none;
  }
  .open .container {
    transform: none;
    transition: transform 0s ease;
  }

  .open .drawer {
    right: 0;
    width: 100%;
  }
}

.maxWidth {
  max-width: 1620px;
}
/* 
@media (max-width: 1920px) {
  .maxWidth {
    max-width: 1240px;
  }
} */
