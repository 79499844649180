.placeholder {
  color: var(--dig-color__border__base);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 0px;
  left: 10px;
  font-size: 16px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.input {
  outline: 0;
  caret-color: var(--dig-color__text__base);
}
