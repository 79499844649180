.container {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__macro__xsmall);
  width: 100%;
}

.container .item {
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-spacing__micro__small);
}

.itemHeader {
  display: flex;
  min-height: var(--dig-spacing__macro__large);
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__large);
  align-items: center;
}

.headerText {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.itemHeaderContent {
  display: flex;
  align-items: flex-start;
  padding: 0px var(--dig-spacing__macro__small) 0px 2px;
  gap: var(--dig-spacing__micro__medium);
  flex: 1 0 0;
}

.iconContainer {
  display: flex;
  align-items: flex-start;
  gap: var(--dig-spacing__micro__small);
}

.itemRow {
  display: flex;
  min-height: 28px;
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__large)
    var(--dig-spacing__micro__medium) var(--dig-spacing__macro__xlarge);
  align-items: center;
  border-top: 1px solid var(--dig-color__border__subtle);
  justify-content: space-between;
}

.itemHeader:hover,
.itemRow:hover {
  background: var(--dig-color__opacity__surface);
  cursor: pointer;
}

.itemRowContainer {
  display: flex;
  align-items: flex-start;
  padding: 0px 2px;
  gap: var(--dig-spacing__micro__medium);
  flex: 1 0 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.wrapper {
  display: flex;
  padding: 1px var(--dig-spacing__micro__xsmall) 0px var(--dig-spacing__micro__xsmall);
  justify-content: center;
  align-items: center;
}
