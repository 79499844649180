.update:hover {
  background-color: var(--dig-color__opacity__surface);
}

.editButton {
  visibility: hidden;
}

.tableRow:hover .editButton {
  visibility: visible;
}

.dragHandle {
  visibility: hidden;
}

.nameCell:hover .dragHandle {
  visibility: visible;
}

.nameCell :global(.dig-Button-icon-start-wrapper) {
  margin-right: 12px;
}
