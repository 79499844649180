.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.item {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing__unit--1_5);
}

.details a {
  display: table;
}

.details a:hover {
  text-decoration: underline;
}

.showMore {
  cursor: pointer;
}

.icon {
  box-sizing: border-box;
  border: 1px solid var(--dig-color__border__subtle);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: var(--dig-color__text__subtle);
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1px;
  background-color: var(--dig-color__border__subtle);
  position: relative;
  grid-column: span 2;
  margin-top: 16px;
}

.moreButton {
  position: absolute;
  background-color: var(--dig-color__background__subtle) !important;
}

@media only screen and (max-width: 980px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}
