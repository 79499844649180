.title {
  padding-bottom: var(--spacing__unit--2);
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 860px) {
  .body {
    flex-direction: column;
  }
}
