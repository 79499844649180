.container {
  color: var(--dig-color__text__subtle) !important;
  background-color: var(--dig-color__primary__surface) !important;
}

.badge {
  position: absolute;
  right: -2px;
  bottom: 0;
}
