.draftChip {
  background: var(--dig-color__selection__surface--state-1);
  padding: 4px 8px;
}

.subtext {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}