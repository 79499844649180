:global(.dig-Button) {
  font-family: var(--type__body__standard--fontfamily);
  font-weight: var(--type__body__standard--fontweight_strong);
  letter-spacing: 0;
}

:global(.dig-Button--standard),
:global(.dig-Button--medium) {
  font-size: var(--type__body__standard--fontsize);
}

:global(.dig-Button--outline) {
  border-color: var(--dig-color__border__subtle);
}

:global(.dig-IconButton) {
  border-radius: 100% !important;
}

:global(.dig-IconButton:not(.dig-Modal > .dig-IconButton)) {
  color: var(--dig-color__text__subtle) !important;
}
