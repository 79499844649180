.selectedBadge {
  width: 100%;
  box-sizing: border-box;
}

.description {
  padding-bottom: var(--spacing__unit--3);
  border-bottom: 1px solid var(--dig-color__border__subtle);
  margin-bottom: var(--spacing__unit--2_5);
}

.noNominations {
  margin-top: var(--spacing__unit--1);
  margin-bottom: var(--spacing__unit--1);
}

.actions {
  flex-shrink: 0;
}

.award {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1);
}

.awardBadges {
  padding-top: var(--spacing__unit--1);
}

.peopleSelect {
  padding-bottom: var(--spacing__unit--2);
}

.submit {
  align-self: flex-end;
}

@media only screen and (max-width: 860px) {
  .selectedBadge {
    padding: 0 var(--spacing__unit--2);
  }
}
