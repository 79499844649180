.drawerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.container {
  position: absolute;
  top: 51px;
  right: 0;
  height: calc(100% - 64px);
  width: 306px;
  border-top: 1px solid var(--dig-color__border__subtle);
  border-left: 1px solid var(--dig-color__border__subtle);
  background-color: var(--dig-color__background__base);
  padding: 32px 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
}

.open {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;
}

.badges {
  display: flex;
  align-items: center;
  gap: 7px;
  align-self: stretch;
}

.header {
  display: flex;
  padding-top: 24px;
  padding-bottom: 16px;
  flex-direction: column;
  gap: 16px;
}

.texts {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 8px 16px 8px;
  border-top: 1px solid var(--dig-color__border__subtle);
}

.disclaimer {
  display: flex;
  padding: 24 0px;
  margin-top: auto;
  text-align: center;
}

.item {
  display: flex;
  padding: 4px 0px;
  gap: 4px;
}

.item .icon {
  width: 24px;
  height: 24px;
  padding-top: 2px;
}

.iconDefault {
  color: var(--dig-color__border__subtle);
}

.iconCheck {
  color: var(--dig-color__primary__base);
}

.item .text {
  display: flex;
  padding-top: 2px;
  flex-direction: column;
}

@media (max-width: 1328px) {
  .container {
    width: 280px;
  }
}

@media (max-width: 1120px) {
  .container {
    width: 180px;
  }
}
