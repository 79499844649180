.itemLink > span {
  display: flex;
  justify-content: left;
  font-weight: var(--type__body__standard--fontweight) !important;
  color: var(--dig-color__text__subtle);
}

.collapsedLeftNav .activeItemLink {
  background: var(--dig-color__opacity__surface);
}

.itemIcon {
  flex-shrink: 0;
  color: var(--dig-color__text__subtle);
}

.modal {
  width: 100% !important;
  max-width: 1024px;
  height: 956px;
}

.animateScroller {
  opacity: 0;
  transition:
    transform 0.2s ease-in-out,
    opacity 0.7s;
  transform: translateX(-50px);
}

.animateScrollerShort {
  opacity: 1;
  transform: translateX(0);
}

.animateScrollerLong {
  opacity: 1;
  transform: translateX(0);
}
