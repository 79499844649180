/* stylelint-disable dig/var-recommended-colors */
.text {
  margin-left: 12px;
}

.editor {
  z-index: 1;
  width: 400px;
  transition: opacity 0.2s;
  will-change: transform;
  margin-bottom: 100px;
}

.view {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
}

.commenting {
  background: rgba(0, 195, 255, 0.14);
  border-bottom: 2px solid rgba(0, 26, 255, 0.3);
  padding-bottom: 1px;
  pointer-events: none;
}

.commenting:hover {
  opacity: 1;
}

.focused,
.highlighted,
.comment {
  background: transparent;
  border-bottom: 2px solid rgba(255, 212, 0, 0.5);
  padding-bottom: 1px;
  padding-top: 1px;
}

.focused,
.highlighted {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}

.actions {
  visibility: hidden;
}

.commentRow:hover .actions {
  visibility: visible;
}
