/* stylelint-disable dig/var-recommended-colors */
/* Default styles (subtle large) */

.title {
  margin: var(--spacing__unit--0_5) 0;
  font-family: var(--type__body__large--fontfamily);
  font-size: var(--type__title__small--fontsize);
  font-weight: var(--type__title__small--fontweight_strong);
  line-height: var(--type__title__small--lineheight);
  -webkit-font-smoothing: antialiased;
}

.text {
  color: var(--dig-color__text__subtle);
  font-family: var(--type__body__large--fontfamily);
  font-size: var(--type__body__large--fontsize);
  font-weight: var(--type__body__large--fontweight);
  line-height: var(--type__body__large--lineheight);
  -webkit-font-smoothing: antialiased;
}

.bold {
  color: var(--dig-color__text__base);
  font-weight: var(--type__body__standard--fontweight_strong);
  line-height: 20px;
}

.link {
  text-decoration: underline;
  color: inherit;
  font-weight: inherit;
}

.link:hover {
  text-decoration: none;
}

/* Shared styles */

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.strikethrough {
  text-decoration: line-through;
}

.underlineStrikethrough {
  text-decoration: underline line-through;
}

.ol {
  margin: 0 0 0 20px;
  list-style-type: decimal;
}

.ul {
  margin: 0 0 0 24px;
  list-style-type: disc;
}

.li {
  margin: 0 !important;
}

.nestedList {
  list-style-type: none;
}

.mark {
  background: transparent;
  border-bottom: 2px solid rgba(255, 212, 0, 0.5);
  padding-bottom: 1px;
  padding-top: 1px;
}

.mark:hover {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}

.markOverlap {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.9);
}

.markOverlap:hover {
  background: rgba(255, 212, 0, 0.7);
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}

.mark.selected {
  background: #ffd40080;
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}

.markOverlap.selected {
  background: #ffd40080;
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}

.table {
  width: 100%;
  border: 1px solid var(--dig-color__border__base);
  border-collapse: collapse;
}

.td {
  padding: 4px 8px;
  border: 1px solid var(--dig-color__border__base);
}
