.statusButton {
  height: 32px;
}

.statusButton :global(.dig-Button-content) {
  padding: 4px 8px;
}

.statusButton :global(.dig-Button-content) > span:first-of-type {
  margin-right: 6px;
}

.statusButton :global(.dig-Button-content) {
  height: 100%;
}

.removePadding :global(.dig-Button-content) {
  padding: 0;
}

.statusButton:disabled {
  border-color: transparent;
}
