.feed {
  margin-right: 4px;
}

.modify {
  margin-right: 8px;
}

:root {
  /* stylelint-disable-next-line dig/var-recommended-colors */
  --box-shadow-color: rgba(30, 25, 25, 0.03);
}

.image {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.image img {
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
}

.landscape {
  width: 98px;
  height: 64px;
  border-radius: 6px;
}

.smallLandscape {
  width: 61px;
  height: 40px;
}

.skeleton {
  top: 0;
  left: 0;
  position: absolute;
  visibility: visible;
  background-size: initial;
}
