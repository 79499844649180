.profileBodySection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.profileNameCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: center;
}

.profileNameHeader {
  display: flex;
  align-items: flex-end;
  gap: 6px;
}

.profileDotDivider {
  width: 18px;
  text-align: center;
}

.profilePronunciationIcon {
  color: var(--dig-color__text__subtle);
  cursor: pointer;
  position: relative;
  bottom: 2px;
}

.goals {
  width: 100%;
}

.profileNameAvatar {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.teamName :hover {
  text-decoration: underline;
  cursor: pointer;
}

.gratitudeButton {
  margin-top: 8px;
}

@media only screen and (max-width: 860px) {
  .profileNameHeader {
    flex-direction: column;
  }
}
