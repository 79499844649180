.saveButtonContainer {
  display: flex;
  padding: 20px 0px 24px 0px;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
}

.saveButtonItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.form {
  margin-top: 52px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 8px;
  padding-right: 36px;
}
