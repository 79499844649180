@import './global.module.css';
@import './forms.module.css';
@import './modal.module.css';
@import './typography.module.css';
@import './button.module.css';
@import './avatar.module.css';
@import './drawer.module.css';
@import './menu.module.css';

:global(.dig-Skeleton__text) {
  margin: 3px 0;
}
