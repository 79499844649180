.alignmentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
}

.objectiveContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.addKeyResultButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancelButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.strategySelectOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.objectiveActionItemIcon {
  color: var(--dig-color__text__subtle);
}

.keyResultContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.keyResultItemContainer {
  width: 100%;
}

.addKeyResultButton {
  margin-left: -16px;
  color: var(--dig-color__text__subtle);
}

.menuContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.extraMarginBottom {
  margin-bottom: 16px;
}

.parentGoalSection:hover {
  cursor: pointer;
  background-color: var(--dig-color__opacity__surface--state-1);
  border-color: transparent;
}

@media only screen and (max-width: 860px) {
  .menuContainer {
    display: block;
  }
}
